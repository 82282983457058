import { ModuleWithProviders, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

export const APPLICATION_IMPORTS: Array<Type<unknown> | ModuleWithProviders<unknown> | unknown[]> = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  LoggerModule.forRoot({
    level: NgxLoggerLevel.DEBUG,
    disableConsoleLogging: false,
    serverLogLevel: NgxLoggerLevel.FATAL,
  }),
];
