// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from '@azure/msal-browser';
import { API_URL, INTERNAL_API_URL, PathwaysEnvironment } from '../types/environment.type';

const B2C_INSTANCE = 'kaplanb2cdev';
const B2C_DOMAIN = 'identity.pathways-dev.kapintdc.com';

export const environment: PathwaysEnvironment = {
  production: false,
  API_BASE_URL: API_URL.DEV,
  INTERNAL_API_BASE_URL: INTERNAL_API_URL.DEV,
  B2C_DOMAIN: B2C_DOMAIN,
  B2C_BASE_PATH: `https://${B2C_DOMAIN}/${B2C_INSTANCE}.onmicrosoft.com/`,
  B2C_CLIENT_ID: '4a26e4ae-b78e-4e1b-a36c-0395a580420f',
  B2C_LOG_LEVEL: LogLevel.Verbose,
  B2C_CUSTOM_POLICIES: {
    PASSWORD_RESET: 'B2C_1A_CP_PasswordReset',
    SIGN_IN: 'B2C_1A_CP_SignIn',
    SIGN_IN_SAML: 'B2C_1A_signin_saml',
    EDIT_PHONE: 'B2C_1A_ProfileEdit_PhoneNumber',
  },
  STUDENT_APPS_URLS: {
    OPP: 'https://payments-pathways.us-east-1.tst.payments.ki-emea-tst.aws.kapintdc.com/?portal=true',
    // INFO: Same url as stage
    STAR_REZ: 'https://kaplanint.starrezhousing.com/StarRezPortalDev/InitiateLogin/StarNet.StarRez.AuthProviders.ADFSStudent',
    VLE: 'https://pathways-vle.ki-emea-tst.aws.kapintdc.com/auth/azureb2c/',
  },
  PORTAL_PATH: 'http://localhost:4200',
  PRIVACY_URL: 'https://www.kaplanpathways.com/privacy/',
  SUPPORT_EMAIL: 'pathways@kaplan.com',
  CLARITY_HASH: 'lxz9b3b1sf',
  GOOGLE_TAG_MANAGER: {
    ID: 'GTM-M32CX4Q',
    AUTH: 'VbwHtYzSy4qc0CKYJ-TD3A',
    ENV: 'env-9',
  },
  BROADCAST_CHANNEL: {
    SIGN_OUT: 'pw-sign-out-local',
  },
  HESA: {
    FORM_URL: 'https://edi-form.formstack.com/forms/edi_form_dev',
    STUDENT_ID_FIELD_ID: 'field133700971',
  },
  AZURE_AD_APPLICATION_ID: '995191e3-a93b-4abf-894b-84ab985b0bf6',
  ENV: 'local',
  MAINTENANCE_MODE: false,
  PRIVATE_ACCOMMODATION_URL: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
  PRE_ARRIVAL_CHECKLIST_ENABLED: true,
  PRE_ARRIVAL_CHECKLIST_TASKS: {
    BUIntCol: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    UBIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    UWEBIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    UEIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    GIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    LIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    KICL: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    TUNIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    NTIC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    UYIPC: [
      {
        name: 'college_arrival_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_college_pre_arrival_dev',
      },
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
      {
        name: 'address_confirmation',
        url: 'https://kicpathways.formstack.com/forms/pws_address_confirmation_form_dev',
      },
    ],
    KIP: [
      {
        name: 'online_registration',
        url: 'https://kicpathways.formstack.com/forms/pws_registration_form_dev',
      },
    ],
  },
  FEATURE_FLAGS: {
    ATTENDANCE_OVERVIEW_ENABLED: true,
    PAYMENTS_NOTIFICATION_ENABLED: true,
  },
};
